import { useState } from "react";
import { APP_API_URL } from "../../../config/Environment";
import { useToken } from "./token";

const defaultOpts = { method: "GET", values: null };

export const useFetchOnDemand = (
  opts = defaultOpts,
) => {
  const { token, LiveToken } = useToken();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  function combineData(currentData, addedData) {
    let data = currentData.data.concat(addedData.data);
    currentData.data = data;
    return currentData;
  }

  const getData = async function (input) {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const url = APP_API_URL + input;
    const { readBody = (body) => body.json() } = opts;

    await LiveToken().validateToken();

    if (token) {
      setLoading(true);
      let tokenType =
        token.tokenType.charAt(0).toUpperCase() +
        token.tokenType.substr(1).toLowerCase();

      try {
        const response = await fetch(url, {
          method: opts.method,
          headers: {
            Authorization: `${tokenType} ${token.token}`,
          },
          signal: signal,
          ...(opts?.values && { body: JSON.stringify(opts.values) }),
        });

        if (response.ok) {
          const body = await readBody(response);
          setLoading(false);
          return body;
        } else {
          const body = await readBody(response);
          console.log("error", body);
          setError(body.message);
          setLoading(false);
        }
      } catch (e) {
        console.log("E", e);
        setError("An error occurred while fetching data.");
        setLoading(false);
      }
    }
  }

  const getAllData = async function (input, all) {
    try {
      const results = await getData(input);
      if (results) {
        if (results.links && results.links.next && all) {
          return combineData(results, await getAllData(results.links.next));
        } else {
          return results;
        }
      }
    } catch (e) {
      console.log("E", e);
    }
  };

  const fetchOnDemand = async (input, all) => {
    return await getAllData(input, all);
  };

  return { fetchOnDemand, error, loading, token };
};
