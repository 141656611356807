import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import { useFetchOnDemand } from "../Base/Hooks/fetchOnDemand";

import CredentialRow from "./CredentialRow";
import NewCredentials from "./NewCredentials";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import SearchFilter from "../Base/Shared/SearchFilter";

import "./credentials.scss";

const Credentials = (props) => {
  const { title, updateState, history, match, intl, mainState } = props;
  const { formatMessage } = intl;
  const [pageTitle, setPageTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [seasons, setSeasons] = useState(null);
  const [entities, setEntities] = useState(null);
  const [selectedCompetitions, setSelectedCompetitions] = useState([]);
  const [selectedSeasons, setSelectedSeasons] = useState([]);

  const [tableData, setTableData] = useState();
  const pageRef = useRef();

  const { fetchOnDemand, token: fetchOnDemandToken } = useFetchOnDemand();

  const buildFetchUrl = (type, query) => {
    const base = `/v1/${match.params.sport}/organizations/${match.params.organizationId}`;
    if (query) {
      return `${base}/${type}?limit=6000&${query}`;
    }
    return `${base}/${type}?limit=6000`;
  }

  const { error, loading, data } = useFetch(
    "/v1/" + match.params.sport + "/organizations/" + match.params.organizationId + "/credentials?limit=6000",
    "",
    refreshFlag,
    "location",
    true,
  );

  const venues = useFetch(
    "/v1/" + match.params.sport + "/organizations/" + match.params.organizationId + "/venues?limit=6000",
    "",
    true,
    "location",
    true,
  );

  const competitions = useFetch(
    "/v1/" + match.params.sport + "/organizations/" + match.params.organizationId + "/competitions?limit=6000",
    "",
    true,
    "location",
    true,
  );

  useEffect(() => {
    if(!entities) {
      fetchOnDemand(buildFetchUrl("entities")).then((data) => setEntities({data: data}));
    }
  }, [fetchOnDemandToken]);

  useEffect(() => {
    if(selectedSeasons.length > 0) {
      Promise.all(selectedSeasons.map(
        (season) => fetchOnDemand(buildFetchUrl("entities", `seasonId=${season}`))
      )).then((entitiesData) => {
        let combinedData = entitiesData.reduce((acc, curr) => {
          return [...acc, ...curr.data];
        }, []);
        setEntities({data: {data: combinedData}});
      });
    } else {
      fetchOnDemand(buildFetchUrl("entities")).then((data) => setEntities({data: data}));
    }
  }, [selectedSeasons]);

  useEffect(() => {
    if(selectedCompetitions.length > 0) {
      Promise.all(selectedCompetitions.map(
        (competition) => fetchOnDemand(buildFetchUrl("seasons", `competitionId=${competition}`))
      )).then((seasonsData) => {
        let combinedData = seasonsData.reduce((acc, curr) => {
          return [...acc, ...curr.data];
        }, []);
        setSeasons({data: {data: combinedData}});
      });
    } else {
      fetchOnDemand(buildFetchUrl("seasons")).then((data) => setSeasons({data: data}));
    }
  }, [selectedCompetitions]);

  const columns = [
    formatMessage({
      id: "name",
      defaultMessage: "Name",
    }),
    formatMessage({
      id: "name.user",
      defaultMessage: "User Name",
    }),
    formatMessage({
      id: "user.type",
      defaultMessage: "User Typd",
    }),
    formatMessage({
      id: "date.expiry",
      defaultMessage: "Expiry Date",
    }),
    "",
  ];

  useEffect(() => {
    if (data && venues.data && competitions.data && entities?.data) {
      setPageTitle(mainState.currentOrganizationName ? mainState.currentOrganizationName : "");

      let filteredData = data !== null ? data.data : [];

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return (
            result.name.toUpperCase().indexOf(searchText.toUpperCase()) > -1 ||
            result.username.toUpperCase().indexOf(searchText.toUpperCase()) > -1
          );
        });
      }

      filteredData = filteredData.sort(function (a, b) {
        var aMixed = a["name"];
        var bMixed = b["name"];
        return aMixed < bMixed ? -1 : 1;
      });

      let tableList = filteredData.map((result, index) => (
        <CredentialRow
          key={index}
          itemIndex={index}
          result={result}
          updateState={updateState}
          match={match}
          venues={venues.data.data}
          entities={entities.data.data}
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
          intl={intl}
          reloadData={reloadData}
          competitions={competitions.data.data}
        />
      ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText, competitions.data, venues.data, entities?.data]);

  function reloadData() {
    setRefreshFlag(true);
  }

  return (
    <React.Fragment>
      {loading && <Spinner size="md" color="orange" />}
      {!loading && (
        <PageDisplay
          title={
            pageTitle +
            " " +
            formatMessage({
              id: "credentials",
              defaultMessage: "Credentials",
            })
          }
          error={error}
          pageTitle={
            pageTitle +
            formatMessage({
              id: "credentials",
              defaultMessage: "Credentials",
            }) +
            " - " +
            title
          }
          ref={pageRef}
          history={history}
        >
          <SearchFilter doSearch={setSearchText} />
          <NewCredentials
            reloadData={reloadData}
            match={match}
            pageRef={pageRef}
            competitions={competitions}
            seasons={seasons}
            entities={entities}
            onCompetitionsChanged={setSelectedCompetitions}
            onSeasonsChanged={setSelectedSeasons}
            venues={venues}
          />
          <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading} />
        </PageDisplay>
      )}
    </React.Fragment>
  );
};

export default injectIntl(Credentials);
